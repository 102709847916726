<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <strong>รายรับ/รายจ่าย</strong>
      <button class="btn btn-sm btn-link" @click="addItem">เพิ่มใหม่</button>
    </div>
    <div class="card">
      <table class="table table-group table-sm table-hover mb-0">
        <thead>
          <tr>
            <th width="60%">รายการ</th>
            <th width="10%">ลำดับ</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td style="vertical-align: middle;">{{ item.item_name }}</td>
            <td>{{ item.sort || '0' }}</td>
            <td class="text-right">
              <button class="btn btn-sm btn-link" @click="editOtherItem(item)">แก้ไข</button>
            </td>
          </tr>
          <tr v-if="items.length == 0">
            <td colspan="3" class="small text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>

    <OtherItemModal :data="data" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import TransactionService from '@/services/TransactionService.js'
import OtherItemModal from './components/OtherItemModal'

const defaultData = {
  _id: null,
  item_type: 'OtherItem',
  item_name: '',
  sort: 0
}

export default {
  name: 'AdminSettingOtherItem',
  components: {
    OtherItemModal
  },
  data() {
    return {
      isShowModal: false,
      data: JSON.parse(JSON.stringify(defaultData)),
      OtherItems: []
    }
  },
  computed: {
    items() {
      return this.OtherItems
    }
  },
  methods: {
    loadOtherItem() {
      TransactionService.gets('OtherItem').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.OtherItems = response.data
        }
      })
    },
    addItem() {
      this.data = JSON.parse(JSON.stringify(defaultData))
      this.isShowModal = true
    },
    editOtherItem(item) {
      console.log(item)
      let data = JSON.parse(JSON.stringify(defaultData))
      this.data = {
        ...data,
        _id: item._id,
        item_type: item.item_type,
        item_name: item.item_name,
        sort: item.sort
      }
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      console.log('needReload', needReload)
      if(needReload) {
        this.loadOtherItem()
      }
    },
  },
  mounted() {
    this.loadOtherItem()
  }
}
</script>
